import { Component, OnInit } from '@angular/core';
import { DbService, MqttService } from '../../services'
import { Household, Card } from '../../interfaces/passera'
import { faPlusCircle, faEye } from '@fortawesome/pro-light-svg-icons'
import { confirm } from 'devextreme/ui/dialog';
import { v4 as uuid } from 'uuid'
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { compileDirectiveFromMetadata } from '@angular/compiler';
import { BnNgIdleService } from 'bn-ng-idle'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  faPlusCircle = faPlusCircle; faEye = faEye;

  searchText = '' 

  mqttSubscription: Subscription

  constructor(public db: DbService, private mqtt: MqttService, private bnIdle: BnNgIdleService) { 

    //let s = localStorage.getItem('divider')
    //if (s) this.divider = +s

    this.setup()

    //setInterval(() => {
    //  this.getData()
    //}, 5 * 60 * 1000)

  }

  ngOnInit(): void {

    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        console.log('idle');
        this.getData()
      }
    })

  }

  async getData() {

    this.db.households = await <any>this.db.httpPost('find', { system: this.db.system, table: 'households', condition: {} })
    console.log('Households', this.db.households.length)

    this.db.cards = await <any>this.db.httpPost('find', { system: this.db.system, table: 'cards', condition: { household_id: { $ne: null }} })
    console.log('Cards', this.db.cards.length)

    this.db.createNames()

  }

  async setup() {

    await this.getData()

      // MQTT
      this.mqtt.subscribe(`himnabadet/scans`)
      this.mqttSubscription = this.mqtt.mqttGetMessage().subscribe((data) => {
        
        //console.log(data.topic, data.message.payload.toString())

        if (data.topic == 'himnabadet/scans') {

          let o = JSON.parse(data.message.payload.toString())

          // If in add card dialog and menu switch is enabled
          if (this.db.addVisible && this.db.scannerSwitch) {
            this.db.card.nr = o.scandata
          }

          if (o && o.action && o.action.substr(0,11) == 'Inpassering') this.db.inpasseringar++

          if (o && o.action) {
            this.db.startpageLog.unshift(`${o.ts.substr(0, 16)} ${o.scandata} ${o.action}`)
            if (this.db.startpageLog.length > 20) this.db.startpageLog.pop()
          }
          
        }
        
      })

  }

}
