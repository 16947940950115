import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { confirm } from 'devextreme/ui/dialog';
import { faHouse, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { Router } from '@angular/router'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-households',
  templateUrl: './households.component.html',
  styleUrls: ['./households.component.scss']
})
export class HouseholdsComponent implements OnInit {

  faHouse = faHouse; faPlusCircle = faPlusCircle;

  householdsDataSource: any = {}

  constructor(public db: DbService, private router: Router) {
    console.log('hhs constr')
    if (!db.households.length) { this.router.navigate(['/start']); return }
    this.db.createDataSource(this.db.system, 'households', this.householdsDataSource)
  }

  ngOnInit(): void {
  }

  async inaktivera() {

    let answer = await confirm(`Inaktivera alla hushåll?`, "Är du säker?")
    if (!answer) return false

    let result = await this.db.httpPost('updatemany', { system: this.db.system, table: 'households', condition: {  }, data: { active: false }, token: this.db.token })

    console.log('Done', result)

  }

  gotoHousehold = (e) => {
    console.log(e)
    this.db.household = e.row.data
    this.db.household.cards = this.db.cards.filter(c => c.household_id == this.db.household.id)
    this.router.navigate([`/household/0`])
  }

  async addHousehold() {

    //if (this.db.activePage != 1) this.router.navigate(['/'])

    this.db.household = null

    let answer = await confirm(`Vill du lägga till ett hushåll?`, "Är du säker?")
    if (!answer) return false

    this.db.household = {
      id: 0,
      firstname: '',
      lastname: '',
      address: '',
      postcode: '',
      city: '',
      phone: '',
      email: '',
      infotext: '',
      active: false,
      cards: []
    }

    this.router.navigate(['/household/0'])

  }

  cardCount = (e) => {
    return this.db.cards.filter(c => c.household_id == e.id).length
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();    
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'households.xlsx');
            });
    });

  }

}
