<div class="outer">

    <div *ngIf="db.household && !db.household.active" class="aa inactive">HUSHÅLL EJ AKTIVT</div>
    <div *ngIf="db.household && db.household.active" class="aa active">AKTIVT HUSHÅLL</div>

    <as-split *ngIf="db.household" direction="horizontal" (dragEnd)="dragEnd($event)">

        <as-split-area [size]="divider">
            <div class="left">

                <!--
                <dx-autocomplete
                    placeholder="Ange förnamn, efternamn eller gatuadress"
                    [(value)]="searchText"
                    [dataSource]="db.names"
                    [showClearButton]="true"
                    [spellcheck]="false"
                    valueExpr="name"
                    (onSelectionChanged)="namesChanged($event)">
                </dx-autocomplete>
                -->
                <div *ngIf="db.household" class="form">

                    <dx-form [formData]="db.household" labelLocation="top" (onFieldDataChanged)="fieldChanged($event)">

                            <dxi-item itemType="group" [colCount]="2">

                                <dxi-item dataField="id">
                                    <dxo-label text="Nummer"></dxo-label>
                                </dxi-item>

                                <dxi-item itemtype="empty"></dxi-item>

                                <dxi-item dataField="firstname">
                                    <dxo-label text="Förnamn"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="lastname">
                                    <dxo-label text="Efternamn"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="address" colSpan="2">
                                    <dxo-label text="Adress"></dxo-label>
                                </dxi-item>

                               <dxi-item dataField="postcode">
                                    <dxo-label text="Postnummer"></dxo-label>
                                </dxi-item>

                               <dxi-item dataField="city">
                                    <dxo-label text="Ort"></dxo-label>
                                </dxi-item>

                               <dxi-item dataField="phone">
                                    <dxo-label text="Telefon"></dxo-label>
                                </dxi-item>

                               <dxi-item dataField="email">
                                    <dxo-label text="E-post"></dxo-label>
                                </dxi-item>

                                <dxi-item dataField="infotext" colSpan="2" editorType="dxTextArea" [editorOptions]="{ width: '100%', minHeight: '100px'}">
                                    <dxo-label text="Noteringar"></dxo-label>
                                </dxi-item>
     
                            </dxi-item>

                    </dx-form>

                    <div class="abuttons">
                        <dx-button *ngIf="db.household.active" type="danger" text="Inaktivera" (onClick)="inActivate()"></dx-button>
                        <dx-button *ngIf="!db.household.active" type="success" text="Aktivera" (onClick)="activate()"></dx-button>
                    </div>
                    
                    <div class="activations">
                        <fa-icon *ngIf="!db.activations.length" [icon]="faEye" class="eyeicon" (click)="getActivations()"></fa-icon>
                        <table *ngIf="db.activations.length">
                            <tr *ngFor="let a of db.activations">
                                <td>{{a.ts}}</td>
                                <td>{{a.action}}</td>
                            </tr>
                        </table>
                    </div>

                </div>

                <!--<pre>{{db.household|json}}</pre>-->
            
            </div>
        </as-split-area>

        <as-split-area [size]="100 - divider">
            <div class="right">

                <div *ngIf="db.household" class="cardgrid">
                    <app-card *ngFor="let card of db.household.cards" [card]="card"></app-card>
                    <div *ngIf="db.household && db.household.id"><fa-icon [icon]="faPlusCircle" class="addicon" (click)="addCard()"></fa-icon></div>
                </div>
    
            </div>
        </as-split-area>

    </as-split>

</div>

<dx-popup
    [width]="400"
    [height]="300"
    [showTitle]="true"
    title="Lägg till kort"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [(visible)]="db.addVisible">
    <div *dxTemplate="let data of 'content'">

        <div class="dx-field">
            <dx-text-box [(value)]="db.card.nr" placeholder="Kortnummer" width="100%">
            </dx-text-box>
        </div>

        <div class="dx-field">
            <dx-text-box [(value)]="db.card.fornamn" placeholder="Förnamn" width="100%">
            </dx-text-box>
        </div>

        <div class="dx-field">
            <dx-text-box [(value)]="db.card.efternamn" placeholder="Efternamn" width="100%">
            </dx-text-box>
        </div>

        <div class="btn">
            <dx-button type="normal" text="Lägg till" (onClick)="doAdd($event)"></dx-button>
        </div> 
        
        <div *ngIf="errorMessage" class="error">{{errorMessage}}</div>

    </div>
</dx-popup>