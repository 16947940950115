import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { CardsComponent } from './components/cards/cards.component';
import { HouseholdsComponent } from './components/households/households.component';
import { LogComponent } from './components/log/log.component';
import { InpasseringarComponent } from './components/inpasseringar/inpasseringar.component';
import { HouseholdComponent } from './components/household/household.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'start', component: MainComponent },
  { path: 'cards', component: CardsComponent },
  { path: 'household/:id', component: HouseholdComponent },
  { path: 'households', component: HouseholdsComponent },
  { path: 'log', component: LogComponent },
   { path: 'inpasseringar', component: InpasseringarComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
