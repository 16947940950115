import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { faPlusCircle, faAddressCard, faHouse, faInfoCircle, faChartBar, faRunning, faUser } from '@fortawesome/pro-light-svg-icons'
import { confirm } from 'devextreme/ui/dialog';
import { Router } from '@angular/router'

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  faPlusCircle = faPlusCircle; faAddressCard = faAddressCard; faHouse = faHouse; faInfoCircle = faInfoCircle; 
  faChartBar = faChartBar; faRunning = faRunning; faUser = faUser;

  constructor(public db: DbService, private router: Router) { }

  ngOnInit(): void {
  }

  namesChanged = (e) => {

    console.log(e)

    //if (this.db.activePage != 1) this.router.navigate(['/'])

    if (!e.selectedItem) return this.db.household = null
    
    let item = e.selectedItem

    let idh = item.idh ? item.idh : item.id

    this.db.household = this.db.households.find(h => h.id == idh)
    this.db.household.cards = this.db.cards.filter(c => c.household_id == idh)

    this.router.navigate(['/household/0'])
    
    //console.log(this.db.cards.filter(c => c.idhushall == '1351'))

  }

  async addHousehold() {

    //if (this.db.activePage != 1) this.router.navigate(['/'])

    this.db.household = null

    let answer = await confirm(`Vill du lägga till ett hushåll?`, "Är du säker?")
    if (!answer) return false

    this.db.household = {
      id: 0,
      firstname: '',
      lastname: '',
      address: '',
      postcode: '',
      city: '',
      phone: '',
      email: '',
      infotext: '',
      active: false,
      cards: []
    }

    this.router.navigate(['/household/0'])

  }

  menuClick = () => {

    this.db.drawerOpen = !this.db.drawerOpen

  }

  cardsClick() {
    this.router.navigate(['/cards'])
  }

  householdsClick() {
    this.router.navigate(['/households'])
  }

  startClick() {
    this.router.navigate(['/start'])
  }

  chartClick() {
    this.router.navigate(['/inpasseringar'])
  }

  logClick() {
    this.router.navigate(['/log'])
  }

}
