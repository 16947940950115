import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { Router } from '@angular/router';
import { faHouse } from '@fortawesome/pro-light-svg-icons'
import { Card } from '../../interfaces/passera'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  faHouse = faHouse;

  cardsDataSource: any = {}
  households = []

  constructor(public db: DbService, private router: Router) {
    if (!db.households.length) { this.router.navigate(['/start']); return }
    this.db.createDataSource(this.db.system, 'cards', this.cardsDataSource)
    this.getHouseholds()
  }

  ngOnInit(): void {
  }

  async getHouseholds() {
    this.households = await <any>this.db.httpPost('find', { system: this.db.system, table: 'households', condition: {}, projection: { _id: 0, id: 1, name: { $concat: ['$firstname', ' ', '$lastname'] } } })
  }

  gotoHousehold = (e) => {
    
    let card: Card = e.row.data
    let hh = this.db.households.find(hh => hh.id == card.household_id)
    if (!hh) return

    this.db.household = hh
    this.db.household.cards = this.db.cards.filter(c => c.household_id == this.db.household.id)
    this.router.navigate([`/household/0`])

  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();    
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'cards.xlsx');
            });
    });

  }

}
