<div class="outer">

    <dx-data-grid 
        #ordergrid 
        [dataSource]="cardsDataSource" 
        [columnAutoWidth]="true" 
        [hoverStateEnabled]="true" 
        (onToolbarPreparing)="db.onToolbarPreparing($event)"
        (onExporting)="onExporting($event)"
        >

        <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true"></dxo-remote-operations>

        <dxo-filter-row visible="true"></dxo-filter-row>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100]"></dxo-pager>

        <dxo-export [enabled]="true" fileName="cards"></dxo-export>
        <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true" [selectTextOnEditStart]="true"></dxo-editing>
        
        <dxi-column dataField="nr" dataType="number" caption="Kortnummer" sortOrder="asc" width="120" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="fornamn" dataType="string" caption="Förnamn"></dxi-column>
        <dxi-column dataField="efternamn" dataType="string" caption="Efternamn"></dxi-column>
        <dxi-column dataField="household_id" dataType="number" caption="Hushåll" [allowEditing]="false" [editorOptions] = "{disabled: true}">
            <dxo-lookup [dataSource]="households" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>

        <dxi-column type="buttons" [width]="100">
            <dxi-button hint="Visa hushåll" [icon]="db.faSvg(faHouse)" [onClick]="gotoHousehold"></dxi-button>
        </dxi-column>

        <div *dxTemplate="let data of 'gridHeadingTemplate'">
            <div class="gridheading">Kort</div>
        </div>

    </dx-data-grid>

</div>