<div class="outer">

    <dx-data-grid #grid
        [dataSource]="inpasseringarDataSource" 
        [columnAutoWidth]="true" 
        [hoverStateEnabled]="true" 
        (onToolbarPreparing)="db.onToolbarPreparing($event)"
        [(filterValue)]="gridFilterValue"
        (onExporting)="onExporting($event)"
        >

        <dxo-filter-row visible="true"></dxo-filter-row>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100]"></dxo-pager>

        <dxo-export [enabled]="true" fileName="inpasseringar"></dxo-export>
        <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true" [selectTextOnEditStart]="true"></dxo-editing>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

        <dxi-column dataField="year" dataType="string" caption="År" [allowEditing]="false" width="120" [visible]="true"></dxi-column>
        <dxi-column dataField="week" dataType="number" caption="Vecka" sortOrder="desc" [allowEditing]="false" width="100" [groupIndex]="0"></dxi-column>
        <dxi-column dataField="dayName" dataType="string" caption="Dag" [allowEditing]="false" width="120"></dxi-column>
        <dxi-column dataField="inpasseringar" dataType="number" caption="Inpasseringar" width="140" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="manuella" dataType="number" caption="Manuella" width="140" [allowEditing]="true"></dxi-column>
    
        <dxi-column [calculateCellValue]="calculateDaySum" caption="Summa" width="140"></dxi-column>

        <dxo-summary>      

                <dxi-group-item summaryType="sum" column="inpasseringar" displayFormat="{0}" valueFormat="###,##0" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item summaryType="sum" column="manuella" displayFormat="{0}" valueFormat="###,##0" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item summaryType="sum" column="Summa" displayFormat="{0}" valueFormat="###,##0" [alignByColumn]="true"></dxi-group-item>

                <dxi-total-item summaryType="sum" column="inpasseringar" displayFormat="{0}" valueFormat="###,##0"></dxi-total-item>
                <dxi-total-item summaryType="sum" column="manuella" displayFormat="{0}" valueFormat="###,##0"></dxi-total-item>
                <dxi-total-item summaryType="sum" column="Summa" displayFormat="{0}" valueFormat="###,##0"></dxi-total-item>
                
        </dxo-summary>

        <div *dxTemplate="let data of 'gridHeadingTemplate'">
            <div class="gridheading">Inpasseringar {{year}}</div>
        </div>

    </dx-data-grid>

</div>