import { Component, OnInit, Input } from '@angular/core';
import { DbService } from '../../services'
import { Router } from '@angular/router';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  log = []  

  constructor(public db: DbService, private router: Router) {
    if (!db.households.length) { this.router.navigate(['/start']); return }
    this.getLog()
    setInterval(() => { this.getLog() }, 60000)
  }

  ngOnInit(): void {
  }
  
  async getLog() {
    this.log = await <any>this.db.httpPost('find', { system: this.db.system, table: 'scans', condition: {}, sort: { ts: -1 }, limit: 100 })
  }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
          icon: 'refresh',
          onClick: this.getLog.bind(this)
      }
    })

    this.db.onToolbarPreparing(e)

  }

}
