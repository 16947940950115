import { Component } from '@angular/core';
import { DbService } from './services'
import { Router } from '@angular/router'
import config from 'devextreme/core/config'; 
import { licenseKey } from '../devextreme-license'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  li = false
  at = 0

  menuTree = [
    { id: 1, name: 'Start', route: '/'},
    { id: 2, name: 'Hushåll', route: '/households'},
    { id: 3, name: 'Kort', route: '/cards' },
    { id: 4, name: 'Inpasseringar', route: '/inpasseringar' },
    { id: 5, name: 'Logg', route: '/log' }
  ]

  constructor(public db: DbService, private router: Router) {

    config({ licenseKey }); 

    if (window.location.hostname == 'localhost') this.li = true

  }

  menuChange(e) {
    console.log('Menu change', e)
    this.db.activePage = e.itemData.id
    this.router.navigate([e.itemData.route])
    this.db.drawerOpen = false
  }

  onClickSubmit(v) {
   if (this.at++ > 5) return
   if (v.username.toLowerCase() == this.db.u && v.password == this.db.p) this.li = true
  }
  
}
