import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store'
import { v4 as uuid } from 'uuid'
import * as moment from 'moment';
import { Household, Card } from '../interfaces/passera'

@Injectable({
  providedIn: 'root'
})
export class DbService {

  system = 'passera'
  token = uuid()
  loading = false

  dbUrl = 'https://db20.bokad.se/'

  names = []
  cards = []

  household: Household
  households: Household[] = []

  activations = []

  drawerOpen = false

  activePage = 1

  scannerSwitch = true

  inpasseringar = 0
  manuella = 0

  startpageLog = []

  u = 'himnabadet'
  p = 'Baddaren14'

  addVisible = false
  editVisible = false
  
  card: Card 

  constructor(private http: HttpClient) { }

    createDataSource(system, table, dataSource, global = false) {

    //console.log('==>', system, table)

    dataSource.store = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => { 
        if (!loadOptions.sort) loadOptions.sort = [{ selector: "sortorder", desc: false }]
        return this.httpPost('loaddata',  { system, table, loadOptions })
      },
      update: (key, values) => { 
        return this.httpPost('updateone', { system, table, condition: { id: key }, data: values, token: this.token }) 
      },
      insert: (values) => { 
        return this.httpPost('insertone', { system, table, data: values, token: this.token }) 
      },
      remove: (key) => { 
        return <any>this.httpPost('deleteone', { system, table, condition: { id: key }, token: this.token }) 
      },
      byKey: (key) => {
        //console.log('Bykey', key)
        return this.httpPost('findone', { system, table, condition: { id: key } })
      }
    })  

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({ location: 'before', template: 'gridHeadingTemplate' })
  }

  createNames() {

    this.names = []

    this.households.forEach(hh => {
      this.names.push({ id: hh.id, name: `${hh.firstname} ${hh.lastname}${hh.address ? `, ${hh.address}` : ``}`, type: 'household', active: hh.active })
    })

    this.cards.forEach(card => {
      this.names.push({ idh: card.household_id, name: `${card.fornamn} ${card.efternamn}`, type: 'card', active: true })
    }) 

  }

  httpPost(cmd, body) {

    var httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
    }

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(`${this.dbUrl}${cmd}`, body, httpOptions)
        .toPromise()
        .then((res: any) => {
          resolve(res)
        },
          err => {
            reject(err)
          }
        )
    })

    return promise

  }

  faSvg(fa, rotate = 0) {
    let rotation = rotate ? ` transform="rotate(${rotate} ${fa.icon[0]/2} ${fa.icon[1]/2})"` : ''
    return `<svg width="1em" height="1em" viewBox="0 0 ${fa.icon[0]} ${fa.icon[1]}" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="${fa.icon[4]}"${rotation}/></svg>`
  }
  
}
