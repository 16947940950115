<div class="outer">
    <div class="remove" (click)="removeCard()"><fa-icon [icon]="faTimesCircle" class="removeicon"></fa-icon></div>
    <div class="div1" (click)="editCard()">
        <div class="kortnr">{{card.nr}}</div>
        <div class="name">{{card.fornamn}} {{card.efternamn}}</div>
    </div>
</div>

<dx-popup
    [width]="400"
    [height]="220"
    [showTitle]="true"
    title="Redigera kort"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [(visible)]="db.editVisible">
    <div *dxTemplate="let data of 'content'">

        <div class="dx-field">
            <dx-text-box [(value)]="card.fornamn" placeholder="Förnamn" width="100%">
            </dx-text-box>
        </div>

        <div class="dx-field">
            <dx-text-box [(value)]="card.efternamn" placeholder="Efternamn" width="100%">
            </dx-text-box>
        </div>

        <div class="btn">
            <dx-button type="normal" text="Spara" (onClick)="editDone($event)"></dx-button>
        </div>       

    </div>
</dx-popup>
